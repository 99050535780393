<template>
<DataTable  class="p-datatable-lg" ref="dt"  :value="question_list"
        dataKey="id" :filters="filter.filter_list"  filterDisplay="menu"  :loading="loading"
        :paginator="true" :rows="6"
        :rowsPerPageOptions="[6, 10,20,50, 100, 1000]"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
            </template>
            <Column field="question" header="Question" :sortable="true"></Column>
            <Column field="question_type" header="Type" :sortable="true">
                <template #body="slotProps">
                    <div style="width: 200px">
                        <span v-if="slotProps.data.question_type == 1"  class="label label-lg font-weight-bold  label-light-primary label-inline">Multiple Choice (One Answer)</span>
                        <span v-if="slotProps.data.question_type == 2"  class="label label-lg font-weight-bold  label-light-info label-inline">Multiple Answers</span>
                        <span v-if="slotProps.data.question_type == 3"  class="label label-lg font-weight-bold  label-light-warning label-inline">Essay</span>
                        <span v-if="slotProps.data.question_type == 4"  class="label label-lg font-weight-bold  label-light-danger label-inline">True or False</span>
                        <span v-if="slotProps.data.question_type == 5"  class="label label-lg font-weight-bold  label-light-success label-inline">Short Answer</span>
                        <span v-if="slotProps.data.question_type == 6"  class="label label-lg font-weight-bold  label-light-default label-inline">Yes or No</span>
                    </div>
                </template>
            </Column>
            <Column field="is_required" header="Required" :sortable="true">
                <template #body="slotProps">
                        <label v-if="slotProps.data.is_required == 1">Yes</label>
                        <label v-else>No</label>
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <el-dropdown @command="handleCommand">
                            <span class="el-dropdown-link">
                                <a href="javascript:void(0);"  data-toggle="dropdown">
                                    <span class="svg-icon  svg-icon-primary svg-icon-md">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1">
                                                <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                                <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item :command="{ action: 'edit', data: slotProps.data }" ><i class="pi pi-pencil text-primary"></i> Edit Question </el-dropdown-item>
                                    <el-dropdown-item :command="{ action: 'delete', data: slotProps.data }"><i class="pi pi-trash text-primary"></i>  Delete Question</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                    </el-dropdown>
                </template>
            </Column>
            <template #loading>
                Loading customers data. Please wait.
            </template>
            <template #empty>
                No results found.
            </template>
        </DataTable>
</template>
<script>
import { defineComponent, inject, ref, watch } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'

export default defineComponent({
    components: {  DataTable, Column, ElDropdownMenu, ElDropdownItem, ElDropdown },
    props: {
        question_list: {
            default: []
        },
        loading: {
            default: false,
            type: Boolean
        },
        filter: {
            default: []
        },
        generateExcelWatcher: {
            type: Number,
            default: 1
        }
    },
    setup(props) {
        const { handleCommand } = inject('QuestionParent')
        const dt = ref()

        function generateCSV()
        {
            dt.value.exportCSV()
        }

        watch(()=> _.cloneDeep(props.generateExcelWatcher) , (n) => {
            generateCSV()
        })

        return {
            handleCommand,
            dt
        }
    },
})
</script>
