<template>
    <Sidebar :baseZIndex="99"   v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
    <form  ref="questionData" @submit.prevent="insert"  action="post"  novalidate>

        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div  class="w-100">
                    <p>Questions</p>
                    <small v-if="type == 'add'">Add Question</small>
                    <small v-else>Edit Question</small>
                </div>
                <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content">
        <div  class="p-10   pt-0 mt-0" style="height: 1500px;">
                <div class="form-group pt-2">
                    <label>Question type</label>
                    <select class="form-control question-type h55" :disabled="type == 'edit'" v-model="question_type" placeholder="Select question type...">
                        <option value="question-select">Choose question type...</option>
                        <option value="question-one-answer">Multiple Choice (Only One Answer)</option>
                        <option value="question-multiple-answer">Multiple Choice (Multple Answers)</option>
                        <option value="question-comment-essay">Comment/Essay Box</option>
                        <option value="question-true-false">True/False</option>
                        <option value="question-yes-no">Yes/No</option>
                        <option value="question-short-answer">Short Answer</option>
                    </select>
                </div>
                <div class="form-group question-select hide"></div>
                <div class="form-group question-one-answer"  v-show="question_type === 'question-one-answer'">
                    <label>Question text</label>
                    <textarea v-model="questions" name="oneanswer_question" rows="5" class="form-control redactor-o-a" placeholder="Type your question here..."></textarea>

                    <div class=" pt-14 pb-10">
                        <label class="w-100">Answer/Choices</label>
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <a href="javascript:void(0);" @click="addChoices" class="pull-right btn btn-small  btn-info mr-2">
                                    New Choices
                                </a>
                            </div>
                            <div class="col-sm-6 col-12"  v-if="1 == 2">
                                <select  class="form-control question-type h55" v-model="upload_type" placeholder="Select...">
                                    <option value="">Upload choices</option>
                                    <option :value="0">Classess</option>
                                    <option :value="1">Students</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-1"  v-for="(input, index) in inputs" :key="index">
                        <input autocomplete="off" :placeholder="'Choices # '  + (index + 1)" v-model="inputs[index]" v-on:dblclick="removeOne(index)" name="choices[]" type="text" class="form-control"
                        v-if="question_type === 'question-one-answer'" >
                    </div>

                    <textarea name="oneanswer_choices" style="display: none" v-model="questions" rows="5" class="form-control "></textarea>
                    <p class=" gray pull-right">Double click on input to remove answer/choices.</p>

                </div>

                <div class="form-group question-multiple-answer" v-show="question_type === 'question-multiple-answer'">
                    <label>Question text</label>
                    <textarea name="multipleanswer_question"  v-model="questions"  rows="5" class="form-control redactor-q-m-add" placeholder="Type your question here..."></textarea>
                    <div class=" pt-14 pb-10">
                        <label class="w-100">Answer/Choices</label>
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <a href="javascript:void(0);" @click="addChoicesMulti" class="pull-right btn btn-small  btn-info mr-2">
                                    New Choices
                                </a>
                            </div>
                            <div class="col-sm-6 col-12" v-if="1 == 2">
                                <select  class="form-control question-type h55" v-model="upload_type" placeholder="Select...">
                                    <option value="">Upload choices</option>
                                    <option :value="0">Classess</option>
                                    <option :value="1">Students</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-1"   v-for="(input, index) in inputs_multi" :key="index">
                        <input autocomplete="off" :placeholder="'Choices # '  + (index + 1)" v-model="inputs_multi[index]" v-on:dblclick="removeMulti(index)" name="choices_multiple[]" type="text" class="form-control"
                              v-if="question_type === 'question-multiple-answer'" />
                    </div>
                    <textarea style="display: none"  name="multipleanswer_choices" v-model="questions" rows="5" class="form-control"></textarea>
                    <p class=" gray pull-right">Double click on input to remove answer/choices.</p>

                    <div class="form-group pt-4">
                        <label for="minmax-buttons">Limit of answers</label>
                        <div>
                            <p>No limit?</p>
                            <InputSwitch v-model="no_limit" />
                        </div>
                    
                        <InputNumber v-if="!no_limit" id="minmax-buttons" v-model="limits" mode="decimal" showButtons :min="0" :max="inputs_multi.length" />
                    </div>

                </div>

                <div class="form-group question-comment-essay"  v-show="question_type === 'question-comment-essay'">
                    <label>Question text</label>
                    <textarea name="essay_question" v-model="questions" rows="5" class="form-control redactor-c-e-add" placeholder="Type your question here..."></textarea>
                </div>
                <div class="form-group question-comment-essay"  v-show="question_type === 'question-yes-no'">
                    <label>Question text</label>
                    <textarea name="yesno_question" rows="5" v-model="questions" class="form-control" placeholder="Type your question here..."></textarea>
                </div>

                <div class="form-group question-true-false"  v-show="question_type === 'question-true-false'">
                    <label>Question text</label>
                    <textarea v-model="questions" name="truefalse_question" rows="5" class="form-control redactor-t-r-add" placeholder="Type your question here..."></textarea>
                </div>

                <div class="form-group question-short-answer"  v-show="question_type === 'question-short-answer'">
                    <label>Question text</label>
                    <textarea name="shortanswer_question"  v-model="questions" rows="5" class="form-control redactor-s-a-add-2" placeholder="Type your question here..."></textarea>
                </div>
                <div v-if="question_type != 'question-select'" class="check-switch-main pt-2 mt-3 p-t-5 pb-17">
                    <div>
                        <p>Required?</p>
                        <InputSwitch v-model="is_question_required" />
                    </div>
                </div>
                <template v-if="(question_type  != 'question-select') && question_type != 'question-comment-essay' && question_type != 'question-short-answer'">
                    <div class="form-group">
                        <button type="button" @click="isPreview = true, setChart()" class="btn btn-sm btn-light-primary">Preview/Refresh Chart Preview</button>
                    </div>
                    <div class="form-group"  v-show="isPreview">
                        <label>Report type</label>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 pt-3">
                                <RadioButton   id="pie"  :value="1" v-model="chart_type" />
                                <label for="pie" class="checkbox-label">Pie chart</label>  
                            </div>
                            <div class="col-sm-12 col-md-6  pt-3">
                                <RadioButton  id="column" :value="2" v-model="chart_type" />
                                <label for="column" class="checkbox-label">Column chart (Vertical)</label>
                            </div>
                            <div class="col-sm-12 col-md-6  pt-3">
                                <RadioButton   id="area" :value="3" v-model="chart_type" />
                                <label for="area" class="checkbox-label">Area chart</label>
                            </div>
                            <div class="col-sm-12 col-md-6  pt-3">
                                <RadioButton id="line" :value="4" v-model="chart_type" />
                                <label for="line" class="checkbox-label">Line chart</label>
                            </div>

                            <div class="col-sm-12 col-md-6  pt-3">
                                <RadioButton id="rating" :value="5" v-model="chart_type" />
                                <label for="rating" class="checkbox-label">Column Chart (Horizontal)</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" v-show="isPreview">
                        <label class="mb-2">Preview</label>
                        <PieChart v-show="chart_type == 1"  :refresh_pie_chart="refresh_chart" :series="series" :labels="labels"></PieChart>
                        <ColumnChart v-show="chart_type == 2 || chart_type == 5" :is_horizontal="chart_type == 5 ? true : false" :chart_type="chart_type" :refresh_column_chart="refresh_chart" :series="series" :categories="categories" ></ColumnChart>
                        <AreaChart v-show="chart_type == 3"  :refresh_area_chart="refresh_chart" :series="series"  :categories="categories" ></AreaChart>
                        <LineChart v-show="chart_type == 4"  :refresh_line_chart="refresh_chart" :series="series"  :categories="categories" ></LineChart>
                    </div>
                </template>
         </div>
        </div>
        <div class="r-panel-footer drawer-footer--v2">
            <div class="r-panel-footer-content">
                <button type="button" :disabled="loader" @click="display = false" class="cursor-pointer btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                <button type="submit" :disabled="loader"   @click="create"   class="btn btn-sm btn-info save mr-2">
                    <span  v-if="!loader">  Save</span>
                    <span v-else><i class="fas fa-spinner fa-spinner"></i> Saving...</span>
                </button>
            </div>
        </div>
        </form>
        
    </Sidebar>
    <Dialog :header="`Select class`"
            :draggable="false"  :modal="true" v-model:visible="class_dialog" :maximizable="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '30vw'}">
                <select  class="form-control question-type h55" v-model="selected_class" placeholder="Select...">
                        <option value="">Select</option>
                        <option v-for="(item, i) in class_list" :key="i" :value="item.id">{{item.name}}</option>
                </select>
                <template #footer>
                    <div class="pt-5">
                        <button @click="class_dialog = false"  type="button" class="btn btn-secondary">Cancel</button>    
                        <button :disabled="loader" type="button"  @click="loadStudents" class="btn btn-primary mr-2">
                            <span>Select </span>
                        </button>
                    </div>
                </template>
    </Dialog>

</template>
<script>
import  StudentsGetListByClass  from '@/composables/students/StudentsGetListByClass'
import  QuestionsInsert  from '@/composables/questions/QuestionsInsert'
import  QuestionSetChartValue  from '@/composables/questions/QuestionSetChartValue'



import { defineComponent, ref, inject, watch, computed, onMounted, toRef} from 'vue'
import { useToast } from "primevue/usetoast"
import { useStore  } from 'vuex'
import Sidebar from 'primevue/sidebar'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'
import { useConfirm } from "primevue/useconfirm"
import Dialog from 'primevue/dialog'
import RadioButton from 'primevue/radiobutton'
import PieChart from '@/components/charts/PieChart.vue'
import ColumnChart from '@/components/charts/ColumnChart.vue'
import AreaChart from '@/components/charts/AreaChart.vue'
import LineChart from '@/components/charts/LineChart.vue'


import _ from "lodash";

export default defineComponent({
    props: {
        add_question_watcher: {
            type: Number,
            default: 1
        },
        selected_question : {
            default: []
        },
        type: {
            default: 'add',
            type: String
        },
        class_list: {
            default: []
        }
    },
    components:  { Dialog,  Sidebar, InputSwitch, InputNumber, RadioButton, PieChart, ColumnChart, AreaChart, LineChart },
    setup(props,  { root }) {
        const confirm = useConfirm();
        const { utils, store, toast, http, constants } = inject('App')

        const { updateList, getQuestionsList } = inject('QuestionParent');
        const  { student_class_loading, student_class_list, getClassStudentList } = StudentsGetListByClass(http, constants, store)
        const  { insertResponse, insert_loading, insertQuestion } = QuestionsInsert(http, constants, toast)
        const  { categories, series,  labels, setChartDataTrueOrFalse, setChartData } = QuestionSetChartValue(utils)



        let question_type = ref('question-select')
        let inputs_multi = ref([''])
        let inputs_multi_id = ref([0])
        let choices_multi =  ref(0)
        let choices  = ref(0)
        let inputs = ref([''])
        let inputs_id = ref([0])
        let loader = ref(false)
        let questionData = ref('')
        let is_question_required = ref(true)
        let display = ref(false)
        let questions = ref('')
        let no_limit = ref(true)
        let limits = ref(1);
        let upload_type = ref('')
        let class_dialog = ref(false)
        let selected_class = ref('')

        const chart_type = ref(0)
        const refresh_chart = ref(1)
        const isPreview = ref(false)
        
        function addChoicesMulti() {
            choices_multi.value += 1;
            const c = this;
            inputs_multi.value.push(
                ''
            )
            inputs_id.value.push(
                0
            )
        }
        function removeMulti(i) {
            inputs_multi.value.splice(i, 1);
            inputs_id.value.splice(i, 1);
        }
        function addChoices() {
            choices.value += 1;
            const c = this;
            inputs.value.push(
                ''
            )
           inputs_id.value.push(
                0
            )
        }
        function removeOne(i) {
            inputs.value.splice(i, 1);
            inputs_id.value.splice(i, 1);

        }
        function insert() {
            insertQuestion(props.type, question_type.value, is_question_required.value, inputs_id.value, inputs.value,
                inputs_multi.value, questionData.value, props.selected_question.id, chart_type.value)
        }
        watch(upload_type, (n, o) => {
            if(upload_type.value === 0) {
                confirm.require({
                    message: `Are you sure you to upload all active class as choices`,
                    header: 'Ooops!',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        confirm.close()
                        initChoices()
                    },
                    reject: () => {
                    }
                });
            } else if(upload_type.value == 1) {
                    class_dialog.value = true
            }
        });
         watch(insert_loading, (n, o) => {
            if(insert_loading.value === false) {
                display.value = false
                upload_type.value = ''

                if (props.type == 'add')
                    getQuestionsList()
                else
                    updateList(insertResponse.value)
            }
         })

        function initChoices() {
            inputs.value = []
            inputs_id.value = []
            inputs_multi.value = []
            for (let i = 0; i < props.class_list.length; i++) {
                inputs.value.push(
                    props.class_list[i].name
                )
                inputs_multi.value.push(
                     props.class_list[i].name
                )
                inputs_id.value.push(
                    0
                )
            }
        }
        function initStudentsChoices() {
            inputs.value = []
            inputs_id.value = []
            inputs_multi.value = []
            for (let i = 0; i < student_class_list.value.length; i++) {
                inputs.value.push(
                    student_class_list.value[i].name
                )
                inputs_multi.value.push(
                     student_class_list.value[i].name
                )
                inputs_id.value.push(
                    0
                )
            }
        }
        function loadStudents() {
            getClassStudentList(selected_class.value)
        }
        function setChart() {
             if (question_type.value == 'question-true-false')
                 setChartDataTrueOrFalse(chart_type.value, ['True', 'False'])
              else if (question_type.value == 'question-one-answer')
                  setChartData(chart_type.value, inputs.value)
              else if (question_type.value == 'question-multiple-answer')
                setChartData(chart_type.value, inputs_multi.value)
              else if (question_type.value == 'question-yes-no')
                 setChartDataTrueOrFalse(chart_type.value, ['Yes', 'No'])
            
              refresh_chart.value++
        }
        watch(question_type, (n, o) => {
            inputs_multi.value = ['']
            inputs.value = ['']
            inputs_id.value = [0]
        });
        watch(chart_type, (n, o) => {
              setChart()
        });
        
        watch(student_class_loading, (n, o) => {
            if (student_class_loading.value == false) {
                class_dialog.value = false
                initStudentsChoices()
            }
        });
        watch(()=> _.cloneDeep(props.add_question_watcher) , (n) => {
            display.value = true
            inputs.value = []
            inputs_id.value = []
            inputs_multi.value = []
            questions.value = ''
            upload_type.value = ''
            no_limit.value = true
            limits.value = 1
            is_question_required.value = true
            question_type.value = 'question-select'
            chart_type.value = 2
            isPreview.value  = false
         
            
           if (props.type == 'edit') {
                question_type.value = props.selected_question.type
                questions.value = props.selected_question.question
                is_question_required.value = props.selected_question.is_required == 1 ? true : false
                upload_type.value = ''
                chart_type.value = props.selected_question.chart_type
                setTimeout(() => {
                    inputs.value = []
                    inputs_id.value = []
                    inputs_multi.value = []
                    
                    for (let i = 0; i < props.selected_question.choices.length; i++) {
                        let options =   props.selected_question.choices[i]
                        inputs.value.push(options.options);
                        inputs_multi.value.push(options.options);
                        inputs_id.value.push(options.id);
                    }
                    setChart()
                }, 500);
                
           }
        })

        return {
            insert, removeMulti, addChoicesMulti, addChoices, removeOne,
            questions,
            inputs_multi, inputs, setChart,
            question_type,
            is_question_required, isPreview,
            loader,  display, questionData, no_limit, limits, upload_type, class_dialog,
            selected_class, loadStudents, chart_type, series, labels, refresh_chart, categories
        }
    }
})
</script>
