import { ref } from 'vue'
export default function QuestionsDelete(http, constant, toast) {
    const insert_loading = ref(false)
    const insertResponse = ref([])
    async function insertQuestion(type, question_type, is_question_required, inputs_id,
                    inputs, inputs_multi, questionData, question_id, chart_type) {
        let result = true
          if (result) {
              let error = 0
              if (question_type == 'question-one-answer' || question_type == 'question-multiple-answer') {
                  let choices = inputs
                  if (question_type == 'question-multiple-answer')
                      choices = inputs_multi

                  let l = choices.length;
                  if (l > 0) {
                      if (choices[l -1] == '' || choices[l -1] == null) {
                          error = 1
                          toast.add({severity:'error', summary: 'error', detail:'Answer choices cant be empty.', life: 3000})
                      }
                  } else {
                      error = 1
                      toast.add({severity:'error', summary: 'error', detail:'Multiple choice questions must have at least on answer.', life: 3000})
                  }
              }
              if (error == 0) {
                  insert_loading.value = true
                  insertResponse.value = []
                  const f = new FormData(questionData)
                  if (type == 'edit')
                      f.append('question_id', question_id)

                  f.append('option_id',  JSON.stringify(inputs_id))
                  f.append('is_question_required', is_question_required)
                  f.append('question_type', question_type)
                  f.append('chart_type', chart_type)


                  try {
                     const response = await http.post(constant.QUESTIONS_ADD, f)
                     insert_loading.value = false
                     insertResponse.value = response.data.question


                     toast.add({severity:'success', summary: 'Success', detail:'Question successfully added/updated.', life: 3000})
                  }  catch (err) {
                    insert_loading.value = false
                  }
              }
              return;
          }
  }
  return {
    insertResponse, insert_loading, insertQuestion
  }
}