<template>
<div>
<div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div class="d-flex align-items-center flex-wrap mr-1">
            <div class="d-flex align-items-baseline flex-wrap mr-5">
                <ul class="breadcrumb pl-0 breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 font-size-sm">
                    <li class="breadcrumb-item">
                        {{question_list.length}} Total
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="card-toolbar card-actions">
                <a href="Javascript:void(0)" @click="exportQuestions" class="btn font-weight-bolder export-action mr-3 text-893BF7">
                    <i class="fas fa-file-export icon-md text-893BF7"></i>Export CSV Questions
                </a>
                <a @click="addQuestion"  href="Javascript:void(0)" class="btn font-weight-bolder add-action  add-button-margin">
                    <i class=" fas fa-plus-circle icon-md"></i>Add Questions
                </a>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-column-fluid">
    <div class="container">
        <div class="card-header  p-0 pt-0 border-0 bg-none">
            <h3 class="card-title  u-flex">
                <span class="card-label fw-bolder fs-3 mb-1 u-flex-1 pt-2" id="tabView">
                    <SelectButton v-model="selected_view" :options="view_options" :dataKey="value">
                        <template #option="slotProps">
                            <i :class="slotProps.option.icon"></i>
                        </template>
                    </SelectButton>
                </span>
                <div class="p-d-flex p-jc-between me-2 searchbar-width">
                    <span class="p-input-icon-left text-blue">
                        <i class="pi pi-search" />
                        <InputText v-if="selected_view.value == 2" class="tableSearch "  v-model="filter.filter_list['global'].value" placeholder="Search..." />
                        <InputText v-else class="tableSearch" v-model="search" placeholder="Search..." />
                    </span>
                </div>
            </h3>
        </div>
        <div class="card card-custom" v-if="selected_view.value == 2">
            <div class="card-body">
                <questions-table-view :question_list="question_list"  :generateExcelWatcher="generateExcelWatcher" :loading="loading" :filter="filter"></questions-table-view>
            </div>
        </div>
        <div v-else>
            <div v-if="loading" class="text-center align-items-center mt-5 pt-5">
                <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="row" v-if="paginated.length > 0">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(item, i) in paginated" :key="i">
                    <QuestionsCardView :item="item" :index="i"></QuestionsCardView>
                </div>
               <div class="col-12">
                        <Paginator 
                        v-if="!loading"
                        template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                         :rows="per_page"  @page="paginate($event, filtered_list)" :totalRecords="filtered_list.length" :rowsPerPageOptions="[9, 10,20,30,50,100,1000]"> </Paginator>
                </div>
            </div>
            <div v-else>
               <no-results-error v-if="!loading" :message="`No questions found!`"></no-results-error>
            </div>
            </div>
    </div>
</div>
<QuestionsAdd   :selected_question="selected_question"  :add_question_watcher="add_question_watcher" :type="action_type" :class_list="class_list"></QuestionsAdd>
<ConfirmDialog></ConfirmDialog>
</div>
</template>
<script>
import  QuestionsGetList  from '@/composables/questions/QuestionsGetList'
import  QuestionsDelete  from '@/composables/questions/QuestionsDelete'
import  ClassGetList  from '@/composables/class/ClassGetList'
import  usePagination  from '@/composables/common/usePagination'


import { defineComponent, ref, onMounted, inject, reactive, watch, provide, computed } from 'vue'
import QuestionsAdd from './QuestionsAdd.vue'
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import Tag from 'primevue/tag';
import { useConfirm } from "primevue/useconfirm"
import ConfirmDialog from 'primevue/confirmdialog'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'
import SelectButton from 'primevue/selectbutton'
import NoResultsError from '@/components/NoResultsError.vue'
import QuestionsTableView from './QuestionsTableView.vue'
import QuestionsCardView from './QuestionsCardView.vue'
import Paginator from 'primevue/paginator';


export default defineComponent({
    components: { Paginator, QuestionsCardView, QuestionsTableView, SelectButton, QuestionsAdd, InputText, ConfirmDialog, NoResultsError  },
    setup() {

        const confirm = useConfirm()
        const { store, toast, http, constants } = inject('App')
        const { loading, question_list, getQuestionsList } = QuestionsGetList(http, constants, store)
        const { delete_status, deleteQuestion } = QuestionsDelete(http, constants, toast)
        const { class_loading, class_list, getClassList } = ClassGetList(http, constants, store)
        const { activePage, setActivePage, paginated, paginate } = usePagination()

        let selected_question = ref([])
        let is_add = ref(false)
        let add_question_watcher = ref(1)   
        let action_type = ref('')
        const filter = reactive({
            filter_list: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })

        const selected_view = ref({icon: 'pi pi-th-large', value: 1})

        const view_options = ref([
            {icon: 'pi pi-th-large', value: 1},
            {icon: 'pi pi-table', value: 2},
        ]);
        const page = ref(1)
        const per_page = ref(9)
         const current_page = ref({
            first: 1,
            page: activePage.value,
            pageCount: 6,
            rows: per_page.value
        })
        const search = ref('')
        const generateExcelWatcher = ref(1)
        
        function addQuestion() {
            action_type.value = 'add'
            add_question_watcher.value++
        }
        
        function edit(s) {
            selected_question.value = s
            action_type.value = 'edit'
            add_question_watcher.value++
        }

        function deletePrompt(q) {
            selected_question.value = q
            confirm.require({
                message: `Are you sure you want to delete ${ selected_question.value.question}?`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteNow()
                },
                reject: () => {
                }
            });
            return {deletePrompt};
        }
        
        function deleteNow() {
            deleteQuestion(selected_question.value.id)
        }
        
        function handleCommand(c) {
            if (c.action == 'delete')
                deletePrompt(c.data)
            if (c.action == 'edit')
                edit(c.data)
        }

        function updateList(q) {
            const newArr = question_list.value.map(obj => {
            if (obj.id === q.id) {
                return {...obj,
                    chart_type: q.chart_type,
                    choices: q.choices,
                    deletable: q.deletable,
                    id: q.id,
                    is_default: q.is_default,
                    is_required: q.is_required,
                    limits: q.limits,
                    question: q.question,
                    question_type: q.question_type,
                    school_id: q.school_id,
                    type: q.type,
                    user_id: q.user_id
                }
            }
            return obj;
            });
            question_list.value = newArr
        }

        function exportQuestions() {
            window.open(`${process.env.VUE_APP_API_URL}${constants.QUESTIONS_EXPORT}?token=${localStorage.getItem("token")}`)
        }

        const filtered_list = computed(() => {
            return question_list.value.filter((obj) => {
                return (
                    obj.question
                    .toLowerCase()
                    .indexOf(search.value.toLowerCase()) != -1
                );
            });
        });
        
        const pageCount = computed(() => {
            let s = filtered_list.value.length / per_page.value
            return s <= 0 ? 1 : Math.floor(s)
        });

        watch(loading, (n, o) => {
            if (loading.value == false) 
            search.value = ''
        });
        
        watch(search, (n, o) => {
            setActivePage(0)
        });
        
        watch(filtered_list, (n, o) => {
            current_page.value = {
                    first: 0,
                    page: activePage.value,
                    rows: current_page.value.rows,
                    pageCount: filtered_list.value.length
            }
            paginate(current_page.value, filtered_list.value)
        });
        
        watch(delete_status, (n, o) => {
            if (delete_status.value) {
                let index = question_list.value.findIndex(obj => obj.id === selected_question.value.id)
                question_list.value.splice(index, 1)
            }
        });

        onMounted(() => {
            getQuestionsList()
            getClassList()
        })
        provide('QuestionParent', { updateList, getQuestionsList, handleCommand })

        return {
            user: computed(() => store.getters['UserModule/GET_USER']),
            selected_question, is_add, filter, question_list,  loading, add_question_watcher, action_type,
            addQuestion, deletePrompt, edit,
            getQuestionsList, class_list, handleCommand,
            selected_view, view_options, search,
            paginate,paginated, filtered_list, current_page, per_page, page, pageCount, activePage,
            exportQuestions,
            generateExcelWatcher
        }
    },
})
</script>
