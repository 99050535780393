import { ref } from 'vue'
export default function QuestionSetChartValue(utils) {
    const labels = ref([])
    const categories =ref([])
    const series = ref([])
    function setChartDataTrueOrFalse(chart_type, l) {
        if (chart_type == 1) {
                labels.value = l
                series.value = [utils.getRandomNumbers(), utils.getRandomNumbers()]
           } else if(chart_type == 2  || chart_type == 3 || chart_type == 4 || chart_type == 5 ) {
                categories.value = l

               let s = []
               for (let i = 0; i < 2; i++) {
                    s.push(utils.getRandomNumbers())
                }

               series.value = [{
                   name: 'Value',
                   data: s
               }]
           }
   }
   function setChartData(chart_type, inputs) {
        if (chart_type == 1) {
                labels.value = inputs
                series.value = []
                for (let i = 0; i < inputs.length; i++) {
                    series.value.push(utils.getRandomNumbers())
                }
        } else if (chart_type == 2  || chart_type == 3 || chart_type == 4 || chart_type == 5) {
                categories.value = inputs

                let s = []
                for (let i = 0; i < inputs.length; i++) {
                    s.push(utils.getRandomNumbers())
                }

                series.value = [{
                    name: 'Value',
                    data: s
                }]
        }
    }

    return {
        categories, series, labels, setChartData, setChartDataTrueOrFalse
    }
}