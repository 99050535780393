import { ref } from 'vue'
export default function StudentsGetListByClass(http, constant, store) {
    let student_class_loading = ref(false)
    let student_class_list = ref([])

    async function getClassStudentList(class_id) {
        student_class_loading.value = true;
        student_class_list.value = []
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)

            const response = await http.post(constant.STUDENT_LIS_BY_CLASS, f)
            student_class_loading.value = false
            student_class_list.value = response.data.data
        } catch (err) {
            student_class_loading.value = false;
        }
    }
    return {
        student_class_loading, student_class_list, getClassStudentList
    }
}