<template>
    <div class="card card-custom gutter-b card-stretch">
        <div class="card-body pt-5">
                    <span v-if="item.question_type == 1"  class="label label-lg font-weight-bold  label-light-primary label-inline">One Answer</span>
                    <span v-if="item.question_type == 2"  class="label label-lg font-weight-bold  label-light-info label-inline">Multiple Answers</span>
                    <span v-if="item.question_type == 3"  class="label label-lg font-weight-bold  label-light-warning label-inline">Essay</span>
                    <span v-if="item.question_type == 4"  class="label label-lg font-weight-bold  label-light-danger label-inline">True or False</span>
                    <span v-if="item.question_type == 5"  class="label label-lg font-weight-bold  label-light-success label-inline">Short Answer</span>
                    <span v-if="item.question_type == 6"  class="label label-lg font-weight-bold  label-light-default label-inline">Yes or No</span>
                    
                    <span v-if="item.is_required == 1"  class="label label-lg font-weight-bold ml-3 label-light label-inline">Yes</span>
                    <span v-else  class="label label-lg font-weight-bold ml-3 label-light label-inline">No</span>
            <div class="d-flex justify-content-end mtn-22">
                <div class="dropdown dropdown-inline show" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <span class="svg-icon svg-icon-primary svg-icon-md">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1">
                                        <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                        <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :command="{ action: 'edit', data: item }" ><i class="pi pi-pencil text-primary"></i> Edit Question </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'delete', data: item }"><i class="pi pi-trash text-primary"></i>  Delete Question</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="d-flex align-items-center mt-5">
                <a href="#" class="text-dark font-weight-bold text-hover-primary  mb-0">{{ item.question }}</a>
            </div>                            
        </div>
    </div>
</template>
<script>
import { defineComponent, inject } from 'vue'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'

export default defineComponent({
    props: {
        item: {
            default: []
        },
        index: {
            default: 0,
            type: Number
        }
    },
    components: { ElDropdown, ElDropdownItem, ElDropdownMenu }, 
    setup() {
        const { handleCommand } = inject('QuestionParent') 
        return {
              handleCommand
        }
    }
})
</script>
